<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { vaultService } from '../../../services/vault.service';
import { shopService } from '../../../services/shop.service';

import {
  notificationMethods
} from "@/state/helpers";

import {
  required,
  numeric,
} from "vuelidate/lib/validators";

const initForm = { 
    shopId: JSON.parse(localStorage.getItem('user')).shopId,
    supervisorId: JSON.parse(localStorage.getItem("user")).id,
    agentId: null,
    currencyType: null,
    amount: null,
};

export default {
   page: {
    title: "Vault",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
},
    data() {
        return {
            agentList: [],
            title: "Vault",
            user: JSON.parse(localStorage.getItem("user")),
            shopList:[],
            fundingSuccess:false,
            fundingFailed: false,
            responseMessage:"",
            items: [
                {
                    text: "Vault Management",
                    href: "/vault"
                },
                {
                    text: "Teller Funding",
                    active: true
                }
            ],
            currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"rand",
                    currencyName: "RAND"
                },
                {
                    currencyType:"pound",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"euro",
                    currencyName: "EURO"
                }
            ],


            form: {
                shopId: JSON.parse(localStorage.getItem("user")).role === "shop-admin" ? JSON.parse(localStorage.getItem("user")).shopId:null, //From Session is not super-admin
                supervisorId: JSON.parse(localStorage.getItem("user")).id,
                agentId: null,
                currencyType: null,
                amount: null,
                transactionId: 0,
                transactionType:"till-funding-init"
            },

            submitted: false,
            submitform: false,
            submit: false
        };
    },

    validations: {
        form: {
            shopId: JSON.parse(localStorage.getItem("user")).role !== "shop-admin" ? { required } :{}, 
            agentId: {required},
            currencyType: {required},
            amount: { required, numeric },
        },
    },

     created() {
        this.loadAllShops();
    },

    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    methods: { 
        ...notificationMethods,
        async loadAllShops() {
            try {
                await shopService.getAllShops().then(response=>{
                    if(response.responseBody.length>0){
                        if(JSON.parse(localStorage.getItem("user")).role === "shop-admin"){
                            let shop = response.responseBody.filter(shop => shop.id == JSON.parse(localStorage.getItem("user")).shopId );
                            this.agentList = shop[0].agents;
                        }else{
                            this.shopList = response.responseBody;
                        }

                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        setShopAgents(){
            this.agentList = this.form.shopId.agents;
        },

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.submitted = false;
                return;
            } else {
                const {form} = this;
                form.shopId = form.shopId.id;
                if(form.agentId === JSON.parse(localStorage.getItem("user")).id){
                        this.submitted = false;
                        this.fundingSuccess = false;
                        this.responseMessage = 'Action not allowed. You cannot fund your own account.';
                }else{
                    const vaultForm = {
                        "operation" : "fund wallet",
                        "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                        "requestBody" : form
                    };
                
                vaultService.supervisorActivity(vaultForm).then(result=>{
                    if(result.status=="SUCCESS"){
                        this.submitted = false;
                        this.form = Object.assign({}, initForm);
                        this.agentList = []
                        this.fundingSuccess = true;
                        this.responseMessage = result.message;
                    } else {
                        this.submitted = false;
                        this.fundingFailed = true;
                        this.responseMessage = result.message;
                    }
                });
                }
                
            }
        },
    },

};

</script>

<template>
<Layout>
<PageHeader :title="title" :items="items" />
 <div class="card col-md-6" >
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h5>Till Funding</h5>
                </div>
            </div>
        </div>

        <div class="no-gutters">

            <p>Fund teller wallet for transacting. The amount should be the same as the physical cash handed to teller.</p>

            <h5  class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="fundingFailed">
                <i :class="'mdi mdi-block-helper mr-2'"></i>{{responseMessage}}
            </h5>
            <h5  class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="fundingSuccess">
                <i :class="'mdi mdi-check-all mr-2'"></i>{{responseMessage}}
            </h5>
            <form class="needs-registration" @submit.prevent="formSubmit">
                <div class="row" v-if="user.role!=='shop-admin'">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Select Branch</label>
                            <select class="form-control" v-model="form.shopId" :class="{ 'is-invalid': submitted && $v.form.shopId.$error }" v-on:change="setShopAgents()">
                                <option  v-for="(shop, index) in shopList" :value="shop" :key="index" >{{shop.name}}</option>
                            </select>
                            <div v-if="submitted && $v.form.shopId.$error" class="invalid-feedback">
                                <span v-if="!$v.form.shopId.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="agentList.length>0">
                <div class="col-md-4">
                        <div class="form-group">
                            <label>Select Teller</label>
                            <select class="form-control" v-model="form.agentId" :class="{ 'is-invalid': submitted && $v.form.agentId.$error }">
                                <option v-for="(agent, index) in agentList" :value="agent.id" :key="index" :disabled="user.id===agent.id" >{{agent.firstName + " " + agent.lastName}}</option>
                            </select>
                            <div v-if="submitted && $v.form.agentId.$error" class="invalid-feedback">
                                <span v-if="!$v.form.agentId.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="validationCustom02">Amount</label>
                                <div>
                                    <input
                                        id="validationCustom02"
                                        v-model="form.amount"
                                        type="number"
                                        class="form-control"
                                        placeholder="Enter Amount "
                                        :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
                                    />
                                    <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.amount.required">This value is required.</span>
                                        <span v-if="!$v.form.amount.numeric">This value should be a valid number.</span>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class="col-md-4">
                            <div class="form-group">
                                <label>Currency</label>
                                <select class="form-control" v-model="form.currencyType" :class="{ 'is-invalid': submitted && $v.form.currencyType.$error }">
                                    <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                </select>
                                <div v-if="submitted && $v.form.currencyType.$error" class="invalid-feedback">
                                    <span v-if="!$v.form.currencyType.required">This value is required.</span>
                                </div>
                            </div>
                    </div>
                    
                    </div>

                <div class="row">
                    <div class="col-md-4" v-if="!submitted">
                        <button class=" btn btn-primary" type="submit">Fund Teller Wallet</button>
                    </div>
                    <div class="col-md-6">
                        <div class="spinner" v-if="submitted">
                            <i class="ri-loader-line spin-icon"></i>
                        </div>
                    </div>
                    
                </div>
                
            </form>
        </div>
    </div>
    </div>
    </Layout>
</template>